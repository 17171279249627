@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Lugrasimo&display=swap');

*{
    margin: 0;
    padding: 0;
   
}
.cute1{
    margin-left: 20px;
}

.heading{
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 40px; */
    font-size: 30px;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;
}

span3{
    color: rgb(88, 201, 95);
    font-size: 40px;
    text-transform: uppercase;
    margin: 7px;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;
}

.cute1:hover{
    transform: scale(1.1);
    transition: 0.5s;
}
.timeline {
  white-space: nowrap;
  overflow-x: hidden;
  font: normal 16px/1.5 "Helvetica Neue", sans-serif;
  background: rgb(138, 226, 138);
  display: flex;
   justify-content: center;
   align-items: center;
   background: url("../../images/time.jpg") no-repeat;
   background-size: 100% 100%;
   margin-top: 30px;
   
  
}

.timeline ol {
  font-size: 0;
  width: 120vw;
  padding: 250px 0;
  transition: all 1s;

}

.timeline ol li {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 150px;
  height: 3px;
  background: #fff;
  margin-left: 130px;
}

.timeline ol li:last-child {
  width: 280px;
}

.timeline ol li:not(:first-child) {
  margin-left: 14px;
}

.timeline ol li:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 1px);
  bottom: 0;
  width: 12px;
  height: 12px;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #f45b69;
}

.timeline ol li div {
  position: absolute;
  left: calc(100% + 7px);
  width: 280px;
  padding: 15px;
  font-size: 1rem;
  white-space: normal;
  color: black;
  background: white;
}

.timeline ol li div::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ol li:nth-child(odd) div {
  top: -16px;
  transform: translateY(-100%);
}

.timeline ol li:nth-child(odd) div::before {
  top: 100%;
  border-width: 8px 8px 0 0;
  border-color: white transparent transparent transparent;
}

.timeline ol li:nth-child(even) div {
  top: calc(100% + 16px);
}

.timeline ol li:nth-child(even) div::before {
  top: -8px;
  border-width: 8px 0 0 8px;
  border-color: transparent transparent transparent white;
}

.timeline time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.text{
  font-family:  'Caprasimo', cursive;
  font-family: 'Lugrasimo', cursive;

}

.timeline{
  margin-bottom: 100px;
}


/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
@media screen and (max-width: 599px) {
  .timeline ol,
  .timeline ol li {
    width: auto;
  }

  .timeline ol {
    padding: 0;
    transform: none !important;
  }

  .timeline ol li {
    display: block;
    height: auto;
    background: transparent;
  }

  .timeline ol li:first-child {
    margin-top: 25px;
  }

  .timeline ol li:not(:first-child) {
    margin-left: auto;
  }

  .timeline ol li div {
    position: static;
    width: 94%;
    height: auto !important;
    margin: 0 auto 25px;
  }

  .timeline ol li:nth-child(odd) div {
    transform: none;
  }

  .timeline ol li:nth-child(odd) div::before,
  .timeline ol li:nth-child(even) div::before {
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: none;
    border-left: 1px solid white;
    height: 25px;
  }

  .timeline ol li:last-child,
  .timeline ol li:nth-last-child(2) div::before,
  .timeline ol li:not(:last-child)::after,
  .timeline .arrows {
    display: none;
  }
}

/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.page-footer {
  position: fixed;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
  color: black;
  background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
}



.choice-container {
    margin-top: 3rem;
    font-size: 2rem;
    text-align: center;
  }
  .choice-container .choice-head {
    padding: 20px;
    font-weight: 700;
    color: #050A30;
    border-top: 1px solid #90ADC6;
    border-bottom: 2px solid #90ADC6;
    display: inline;
  }
  .choice-container .home-container {
    position: relative;
    margin-top: 5%;
    padding-bottom: 20%;
    padding-top: 10%;
  }
  .choice-container .circular-image {
    width: 30vw;
    height: 60vh;
    border-radius: 50%;
    overflow: hidden;
    background-color: #EEEDE7;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    box-shadow: 1px 1px 10px 5px rgb(173, 172, 172);
    cursor: pointer;
  }
  .choice-container .circular-image img {
    width: 100%;
    height: 100%;
  }
  .choice-container .rotate-image {
    width: 6vw;
    height: 12vh;
    border-radius: 50%;
    overflow: hidden;
    background-color: #EEEDE7;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    left: 32%;
    top: 28%;
    animation: circle 10s infinite linear;
  }
  .choice-container .light-background {
    width: 18vw;
    position: absolute;
    text-align: center;
    font-family: "Lato", sans-serif;
    background-color: #E9EAEC;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px rgb(184, 184, 184);
  }
  .choice-container .light-background h3 {
    margin-bottom: 5px;
    font-size: 1.2rem;
    border-bottom: 1px solid #90ADC6;
    padding-bottom: 5px;
  }
  .choice-container .light-background p {
    text-align: left;
    color: #4e5f6d;
    font-size: 1rem;
  }
  .choice-container .home-interior {
    position: absolute;
    top: 0;
    left: 10%;
  }
  .choice-container .dark-background {
    width: 18vw;
    position: absolute;
    text-align: center;
    font-family: "Lato", sans-serif;
    background-color: #E9EAEC;
    background-color: rgb(88, 201, 95);
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 5px rgb(184, 184, 184);
  }
  .choice-container .dark-background h3 {
    margin-bottom: 5px;
    font-size: 1.2rem;
    border-bottom: 1px solid #90ADC6;
    padding-bottom: 5px;
    color: #E9EAEC;
  }
  .choice-container .dark-background p {
    text-align: left;
    color: #F8EFE4;
    font-size: 1rem;
  }
  .choice-container .home-affordable {
    position: absolute;
    top: 35%;
    left: 2%;
  }
  .choice-container .home-guaranteed {
    position: absolute;
    top: 57%;
    left: 10%;
  }
  .choice-container .home-number {
    position: absolute;
    top: 0%;
    right: 10%;
  }
  .choice-container .home-consultation {
    position: absolute;
    top: 35%;
    right: 2%;
  }
  .choice-container .home-support {
    position: absolute;
    top: 64%;
    right: 10%;
  }
  
  @keyframes circle {
    0% {
      top: 28%;
      left: 32%;
    }
    50% {
      top: 50%;
      left: 32.5%;
    }
    100% {
      top: 25%;
      left: 32%;
    }
  }