@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Lugrasimo&display=swap');
.about-section1{
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../images/group.jpeg) no-repeat cover;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;;
   
    background-size: 55%;
    background-color:rgba(129, 228, 246, 0.689);
    overflow: hidden;
    height: 100%;
    padding: 100px 0;
}
 
.inner-container1{
    width: 55%;
    float: right;
    background-color: #fdfdfd;
    padding: 150px;
box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
font-family:  'Caprasimo', cursive;
font-family: 'Lugrasimo', cursive;;
 
}
.inner-container1 h1{
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 900;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;;
}
 
.text1{
    font-size: 25px;
    color: #545454;
    line-height: 30px;
    text-align: justify;
    margin-bottom: 40px;
    font-family:  "Poppins", sans-serif;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;;
}
h1{
  color:rgb(60, 177, 219);
  font-family:  'Caprasimo', cursive;
  font-family: 'Lugrasimo', cursive;;

}
 
.skills1{
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 13px;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;;
}
 
@media screen and (max-width:1200px){
    .inner-container1{
        padding: 80px;
    }
}
 
@media screen and (max-width:1000px){
    .about-section1{
        background-size: 100%;
        padding: 100px 40px;
    }
    .inner-container1{
        width: 100%;
    }
}
 
@media screen and (max-width:600px){
    .about-section1{
        padding: 0;
    }
    .inner-container1{
        padding: 60px;
    }
}
span1{
  cursor: pointer;
  font-size: 16px;
text-align: center;
padding: 10px;
  width: 500px;
}
span1:hover{
background-image: linear-gradient(to right top, rgb(60, 177, 219), rgb(60, 177, 219), rgb(60, 177, 219),rgb(60, 177, 219),rgb(60, 177, 219));
color: #fff;
border-radius: 20px;
transition: 0.5s;
 
}
.active1{
  background-image: linear-gradient(to right top, rgb(60, 177, 219), rgb(60, 177, 219), rgb(60, 177, 219), rgb(60, 177, 219), rgb(60, 177, 219));
  color: #fff;
  border-radius: 20px;
 
 
}