.containerc{
    width: 1000px;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    
}

.containerc .cardc{
    position: relative;
    cursor: pointer;
    margin: 9px;
   
}

.containerc .cardc .face{
    width: 300px;
    height: 200px;
    transition: 0.5s;
    
}

.containerc .cardc .face.face1{
    position: relative;
    background: rgb(88, 201, 95);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(100px);
  
    
}

.containerc .cardc:hover .face.face1{
    background: rgb(88, 201, 95);
    transform: translateY(0);
  
}

.containerc .cardc .face.face1 .content{
    
    transition: 0.5s;
    
}

.containerc .cardc:hover .face.face1 .content{
    opacity: 1;
}

.containerc .cardc .face.face1 .content img{
    max-width: 100px;
}

.containerc .cardc .face.face1 .content h3{
    margin: 10px 0 0;
    padding: 0;
   
    text-align: center;
    font-size: 1.5em;
}

.containerc .cardc .face.face2{
    position: relative;
    
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(-100px);
}

.containerc .cardc:hover .face.face2{
    transform: translateY(0);
}

.containerc .cardc .face.face2 .content p{
    margin: 0;
    padding: 0;
}

.containerc .cardc .face.face2 .content a{
    margin: 15px 0 0;
    display:  inline-block;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    padding: 5px;
    border: 1px solid #333;
}

.containerc .cardc .face.face2 .content a:hover{
    background: #333;
    color: #fff;
}