@import url('https://fonts.googleapis.com/css2?family=Caprasimo&family=Lugrasimo&display=swap');

.nav1{
    background-color:rgb(88, 201, 95);
    display: flex;
    justify-content: left;
    align-items: center;
    height: 50px    ;
 

}

.text2{
    margin: 10px;
    font-size: 1.2rem;
    font-family:  'Caprasimo', cursive;
    font-family: 'Lugrasimo', cursive;
    color: white;
}